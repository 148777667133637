<template>
  <p class="title">
    <el-icon>
      <ArrowRightBold />
    </el-icon>信息管理
  </p>
  <div class="content" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(0, 0, 0, 0.7)"
    element-loading-text="加载中...">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item label="身份" prop="type">
        <el-radio-group v-model="ruleForm.type" @change="typeChange">
          <el-radio v-for="item in options.list" :key="item.value" :label="item.value - 0" :disabled="isDisabled">
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="真实姓名" prop="name" v-if="ruleForm.type != 2">
        <el-input v-model="ruleForm.name" placeholder="请输入真实姓名" />
      </el-form-item>
      <el-form-item label="院校/机构名称" prop="name" v-if="ruleForm.type === 2">
        <el-input v-model="ruleForm.name" placeholder="请输入院校/机构名称" />
      </el-form-item>
      <el-form-item label="院校机构LOGO" prop="logo" v-if="ruleForm.type === 2">
        <el-upload style="margin-left: 20px" :style="{ width: fileListLogo ? '' : '100%' }" :action="action.url"
          :headers="action.headers" :on-success="handleSuccessLogo" :on-remove="handleRemoveLogo" accept="image/*"
          :limit="1">
          <el-button type="primary">点击上传</el-button>
        </el-upload>
        <img class="img" v-if="fileListLogo" :src="fileListLogo" alt="" />
      </el-form-item>
      <el-form-item label="负责人" prop="contacts" v-if="ruleForm.type === 2">
        <el-input v-model="ruleForm.contacts" placeholder="请输入负责人" />
      </el-form-item>
      <el-form-item label="负责人职务" prop="contactsDept" v-if="ruleForm.type === 2">
        <el-input v-model="ruleForm.contactsDept" placeholder="请输入负责人职务" />
      </el-form-item>
      <el-form-item label="国籍" prop="nationality" v-if="ruleForm.type != 2">
        <el-input v-model="ruleForm.nationality" placeholder="请输入国籍" />
      </el-form-item>
      <div class="upload" v-if="ruleForm.type != 2">
        <el-form-item label="身份证号" prop="cardNo" :style="{ flex: ruleForm.type === 3 ? '' : 1 }">
          <el-input v-model="ruleForm.cardNo" placeholder="请输入身份证号" />
        </el-form-item>
        <el-upload v-if="ruleForm.type === 3" style="margin-left: 20px" :action="action.url" :headers="action.headers"
          :on-success="handleSuccessCardNo" :on-remove="handleRemoveCardNo" accept="image/*" :limit="1">
          <el-button type="primary">点击上传</el-button>
        </el-upload>
        <img class="img" v-if="fileListCardNo" :src="fileListCardNo" />
      </div>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="ruleForm.email" placeholder="请输入邮箱" />
      </el-form-item>
      <el-form-item label="所属院校" prop="colleges" v-if="ruleForm.type === 1">
        <el-input v-model="ruleForm.colleges" placeholder="请输入所属院校" />
      </el-form-item>
      <div class="row">
        <el-form-item label="系部" prop="department" v-if="ruleForm.type === 1">
          <el-input v-model="ruleForm.department" placeholder="请输入系部" />
        </el-form-item>
        <el-form-item label="专业" prop="specialized" v-if="ruleForm.type === 1">
          <el-input v-model="ruleForm.specialized" placeholder="请输入专业" />
        </el-form-item>
      </div>
      <div class="upload" v-if="ruleForm.type < 3">
        <el-form-item label="上传证件">
          <el-select v-if="ruleForm.type === 1" v-model="select.certificateImg" placeholder="请选择上传证件类型">
            <el-option label="学生证" :value="1" />
            <el-option label="身份证" :value="2" />
            <el-option label="其他" :value="3" />
          </el-select>
          <el-select v-else v-model="select.certificateImg" placeholder="请选择上传证件类型">
            <el-option label="营业执照/组织机构代码证" :value="1" />
            <el-option label="其他" :value="2" />
          </el-select>
        </el-form-item>
        <el-upload style="margin-left: 20px" :style="{ flex: fileListCertificateImg ? '' : 1 }" :action="action.url"
          :headers="action.headers" :on-success="handleSuccess" :on-remove="handleRemove" accept="image/*" :limit="1">
          <el-button type="primary">点击上传</el-button>
        </el-upload>
        <img class="img" v-if="fileListCertificateImg" :src="fileListCertificateImg" />
      </div>
      <el-form-item label="年级" prop="department" v-if="ruleForm.type === 4">
        <el-input v-model="ruleForm.department" placeholder="请输入年级" />
      </el-form-item>
      <el-form-item label="学校" prop="colleges" v-if="ruleForm.type === 4">
        <el-input v-model="ruleForm.colleges" placeholder="请输入学校" />
      </el-form-item>
      <el-form-item label="QQ">
        <el-input v-model="ruleForm.qq" placeholder="请输入QQ" />
      </el-form-item>
      <div class="row" v-if="ruleForm.type != 4">
        <el-form-item label="银行卡号">
          <el-input v-model="ruleForm.bankNo" placeholder="请输入银行卡号" />
        </el-form-item>
        <el-form-item label="银行卡开户行">
          <el-input v-model="ruleForm.bankName" placeholder="请输入银行卡开户行" />
        </el-form-item>
      </div>
      <el-form-item label="收货地址">
        <el-input v-model="ruleForm.address" placeholder="请输入收货地址" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm(ruleFormRef)" style="width: 100px">
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ArrowRightBold } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { getZqDictDetail, uploadFile } from "@/api/publish";
import { saveUserInfo } from "@/api/user";
import { ref, reactive, defineProps, watch, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { getToken } from '@/utils/auth'
const router = useRouter();
const props = defineProps({
  data: {
    type: Object,
    default () {
      return {};
    },
  },
});

const emit = defineEmits(["reload"]);
const action = reactive({
  url: process.env.VUE_APP_BASE_API + "file/uploadFile",
  headers: {
    authorization: "zq-website_" + getToken().token,
  },
});
const ruleForm = reactive({
  type: 1,
  name: "",
  logo: "",
  contacts: "",
  contactsDept: "",
  certificateImg: "",
  nationality: "",
  cardNo: "",
  colleges: "",
  department: "", // 年级也是系部
  specialized: "",
  email: "",
  qq: "",
  bankNo: "",
  bankName: "",
  address: "",
});
let isDisabled = ref(false);
const fileListLogo = ref("");
const fileListCertificateImg = ref("");
const fileListCardNo = ref("");
watch(
  () => props.data,
  (newVal) => {
    if (ruleForm.type) {
      Object.assign(ruleForm, newVal);
      if (newVal.type === "null" || !newVal.type) {
        ruleForm.type = 1;
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
      }
      if (newVal.logo) {
        fileListLogo.value = newVal.logo;
      }
      if (newVal.certificateImg) {
        fileListCertificateImg.value = newVal.certificateImg;
      }
      if (newVal.cardImg) {
        fileListCardNo.value = newVal.cardImg;
      }
    }
    ruleForm.name = newVal.name;
  },
  {
    deep: true,
    immediate: true,
  }
);
const options = reactive({
  list: [],
});
const select = reactive({
  certificateImg: 1,
});
let ruleFormRef = ref("");

const rules = reactive({
  name: [
    {
      required: true,
      trigger: ["blur"],
      message: "请输入正确的真实姓名",
      pattern: /\S/g,
    },
  ],
  type: {
    required: true,
  },
  nationality: {
    required: true,
    trigger: ["blur"],
    message: "请输入正确的国籍",
    pattern: /\S/g,
  },
  cardNo: {
    required: true,
    trigger: ["blur"],
    message: "请输入正确的身份证号",
    pattern:
      /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
  },
  email: {
    required: true,
    trigger: ["blur"],
    message: "请输入正确的邮箱",
    pattern: /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/,
  },
  certificateImg: {
    required: true,
    trigger: ["blur"],
    message: "请上传证件图片",
  },
  colleges: {
    required: true,
    trigger: ["blur"],
    message: "请输入内容",
    pattern: /\S/g,
  },
  department: {
    required: true,
    trigger: ["blur"],
    message: "请输入内容",
    pattern: /\S/g,
  },
  specialized: {
    required: true,
    trigger: ["blur"],
    message: "请输入正确的专业",
    pattern: /\S/g,
  },
  logo: {
    required: true,
    trigger: ["blur"],
    message: "请上传证件图片",
  },
  contacts: {
    required: true,
    trigger: ["blur"],
    message: "请输入正确的负责人",
    pattern: /\S/g,
  },
  contactsDept: {
    required: true,
    trigger: ["blur"],
    message: "请输入正确的责任人职务",
    pattern: /\S/g,
  },
});

const fullscreenLoading = ref(false);

const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      fullscreenLoading.value = true;
      saveUserInfo(ruleForm)
        .then((res) => {
          fullscreenLoading.value = false;
          ElMessage({
            message: "保存成功",
            type: "success",
          });
          emit("reload", true);
        })
        .catch((e) => {
          fullscreenLoading.value = false;
        });
    }
  });
};

function handleSuccess (response, uploadFile, uploadFiles) {
  if (response.data) {
    ruleForm.certificateImg = response.data;
  }
}

function handleSuccessLogo (response, uploadFile, uploadFiles) {
  if (response.data) {
    ruleForm.logo = response.data;
  }
}

function handleSuccessCardNo (response, uploadFile, uploadFiles) {
  if (response.data) {
    ruleForm.certificateImg = response.data;
  }
}

function handleRemove (uploadFile, uploadFiles) {
  ruleForm.certificateImg = "";
}

function handleRemoveLogo (uploadFile, uploadFiles) {
  ruleForm.logo = "";
}

function handleRemoveCardNo (uploadFile, uploadFiles) {
  ruleForm.certificateImg = "";
}
getZqDictDetail({
  dictId: 10,
}).then((res) => {
  options.list = res.data;
});

function typeChange () {
  ruleForm.cardNo = "";
  ruleForm.logo = "";
  ruleForm.certificateImg = "";
  select.certificateImg = "";
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  font-size: 26px;
}

.content {
  padding-top: 50px;
  width: 1200px;

  .img {
    width: 50px;
    margin-left: 20px;
  }

  .upload {
    display: flex;
    align-items: flex-start;
  }

  .row {
    display: flex;
    align-items: center;

    .el-form-item {
      flex: 1;
    }
  }

  .el-form-item {
    margin-bottom: 30px;
  }

  ::v-deep .el-input__inner {
    height: 40px;
  }
}
</style>

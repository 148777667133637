<template>
  <div class="con">
    <header class="header">
      <div class="contact-box">
        <img class="logo-img" @click="toHome" :src="website.getWebsiteMsg.logo" alt="" />
      </div>
      <div class="right">
        <span class="name">你好：{{ userInfo.data.name }}</span>
        <el-button type="text" @click="user.LOGIN_OUT">
          <img src="~@/assets/home/out.png" alt="" srcset="">
          <span>退出登录</span>
        </el-button>
        <el-button type="text" @click="toPage('/')">
          <img src="~@/assets/home/home.png" alt="" srcset="">
          <span>返回首页</span>
        </el-button>
      </div>
    </header>

    <div class="content-warp">
      <div class="left-side">
        <ul>
          <li :class="{ on: item.value === currentNav }" v-for="(item, index) in sideNav.list" :key="item.name"
            @click="handleNav(item.value)">
            <img :src="item.bg" alt="">
            <span class="name">{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="right-side">
        <div class="right-con">
          <component :is="currentComponet.com" :data="userInfo.data" @reload="componentSubmit" @reSubmit="reSubmit"
            :editData="editData" @signup="signup"></component>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import {
  getUserInfo
} from '@/api/user'
import information from './components/information.vue'
import signUp from './components/signUp.vue'
import takePart from './components/takePart.vue'
import {
  reactive,
  ref,
  markRaw
} from 'vue';
import {
  useRouter
} from 'vue-router'
import {
  ElMessageBox
} from 'element-plus'
import {
  useWebsite
} from "@/pinia/index";
import { useUser } from '@/pinia/user'

const user = useUser()
const website = useWebsite()

const router = useRouter()
let userInfo = reactive({
  data: {}
})

getUserInfo().then(res => {
  userInfo.data = res.data
})

function componentSubmit () {
  getUserInfo().then(res => {
    userInfo.data = res.data
  })
}

let sideNav = reactive({
  list: [{
    id: 1,
    name: '信息管理',
    bg: require('../../assets/home/card/p0.png'),
    value: 0
  }, {
    id: 2,
    name: '报名入口',
    bg: require('../../assets/home/card/p1.png'),
    value: 1
  }, {
    id: 3,
    name: '我的参赛',
    bg: require('../../assets/home/card/p2.png'),
    value: 2
  }]
})
let tabContent = reactive([{
  name: '信息管理',
  com: markRaw(information)
},
{
  name: '报名入口',
  com: markRaw(signUp)
},
{
  name: '我的参赛',
  com: markRaw(takePart)
},
]);
let currentNav = ref(0)
let currentComponet = reactive({
  com: tabContent[0].com
})

let editData = ref('')

function reSubmit (e) {
  editData.value = e
  currentComponet.com = tabContent[1].com
  currentNav.value = 1
}

function handleNav (i) {
  if (!userInfo.data.email) {
    ElMessageBox.alert('请先填写信息完成认证，认证完成后可进行其他操作', '提示', {
      confirmButtonText: '好的',
      callback: (action) => { },
    })
  } else {
    editData.value = ''
    currentNav.value = i
    currentComponet.com = tabContent[i].com
  }

}

function signup () {
  currentNav.value = 2
  currentComponet.com = tabContent[2].com
}

function toPage (path) {
  router.push({
    path
  })
}

</script>

<style lang="scss" scoped>
.con {
  margin-top: - $headerHeight;

  .header {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e4e4e4;

    em {
      font-style: normal;
      color: $themeColor;
      font-size: 16px;
      cursor: pointer;
    }

    .contact-box {
      position: relative;
      cursor: pointer;

      .logo-img {
        width: 180px;
      }
    }

    .right {
      padding-right: 30px;

      .name {
        display: inline-block;
        margin-right: 20px;
      }

      img {
        width: 25px;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -3px;
      }

      span {
        color: #333;

        &:hover {
          color: var(--themeColor);
        }
      }
    }
  }
}

ul {
  list-style: none;
}

.left-side {
  width: 150px;
  height: 100%;
  float: left;

  li {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    cursor: pointer;

    img {
      width: 50px;
    }

    .name {
      font-weight: 700;
      color: $themeColor;
    }
  }

  .on {
    background-color: #f4fcf8;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: inline-block;
      width: 4px;
      height: 89px;
      background-color: $themeColor;
    }
  }

}

.right-side {
  background-color: #f5f5f5;
  margin-left: 150px;
  padding: 10px;
  height: calc(100vh - 72px);
  overflow: auto;

  .right-con {
    background-color: #fff;
    padding: 24px;
  }
}
</style>

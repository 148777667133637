<template>
	<p class="title">
		<el-icon>
			<ArrowRightBold />
		</el-icon>我的参赛
	</p>
	<el-table :data="tableData.list" style="width: 100%" @row-click="handleRowClick">
		<el-table-column prop="areaName" label="赛区"  />
		<el-table-column prop="themeName" label="赛题" />
		<el-table-column prop="worksName" label="作品名称"  />
		<el-table-column prop="author" label="作者" />
		<el-table-column prop="addDate" label="报名时间"  />
		<el-table-column prop="countryAwarded" label="国赛奖项"  />
		<el-table-column prop="provinceAwarded" label="省赛奖项"  />
		<el-table-column fixed="right" prop="stateStr" label="状态" >
			<template #default="scope">
				<div :class="{state:scope.row.state!=2}"><span>{{scope.row.stateStr}}</span><span class="re-sub"
						v-if="scope.row.state===2">，重新提交</span>
				</div>
			</template>
		</el-table-column>
		<template #empty>
			没有数据
		</template>
	</el-table>
	<div class="pagination-block">
		<el-pagination v-model:current-page="query.num" v-model:page-size="query.pageSize"
			:page-sizes="[20, 50, 100, 200]" layout="total, sizes, prev, pager, next, jumper" :total="total"
			@size-change="handleSizeChange" @current-change="handleCurrentChange" />
	</div>
</template>

<script setup>
	import {
		getWorksList
	} from '@/api/competition'
	import {
		getZqDictDetail
	} from '@/api/publish'
	import {
		ref,
		reactive,
		defineEmits
	} from 'vue'
	import {
		useRouter
	} from 'vue-router'
	const router = useRouter()
	let emits = defineEmits([''])

	let tableData = reactive({
		list: []
	})
	let tableState = reactive({
		list: []
	})
	let query = reactive({
		pageNum: 1,
		pageSize: 20,
	})
	let total = ref(0)

	function handleSizeChange(e) {
		query.pageSize = e
		getList()
	}

	function handleCurrentChange(e) {
		query.pageNum = e
		getList()
	}

	function getList() {
		getWorksList(query).then(res => {
			tableData.list = res.data.data
			total.value = res.data.total
		})
	}
	getList()

	function handleRowClick(row, column, event) {
		let state = row.state
		if (state === 2) {
			emits('reSubmit', row)
		}
	}
	// getZqDictDetail({
	// 	dictId: 11
	// }).then(res => {
	// 	tableState.list = res.data
	// })

	// function formatState(state) {
	// 	let item = tableState.list.find(v => {
	// 		return v.value == state
	// 	})
	// 	if (item) {
	// 		return item.label
	// 	} else {
	// 		return '未知状态'
	// 	}
	// }
</script>

<style lang="scss" scoped>
	.title {
		display: flex;
		align-items: center;
		font-size: 26px;
	}

	.el-table {
		margin: 20px 0;
	}

	.pagination-block {
		display: flex;
		justify-content: flex-end;
	}

	.re-sub {
		color: #409eff;
		text-decoration: underline;
		cursor: pointer;
	}

	.state {
		color: #F56C6C;
	}
</style>

<template>
  <p class="title" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(0, 0, 0, 0.7)"
    element-loading-text="加载中...">
    <el-icon>
      <ArrowRightBold />
    </el-icon>赛事报名
  </p>
  <div class="content">
    <div class="card">
      <p class="title">选择赛区</p>
      <div class="card-con area-list">
        <ul>
          <li v-for="item in list.data.areaList" :class="{ on: ruleForm.areaId === item.id }" @click="handleArea(item)">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="card">
      <p class="title">选择赛题分类</p>
      <div class="card-con">
        <div class="radio-warp">
          <el-radio-group v-model="ruleForm.themeType" @change="handleTypeChange">
            <el-radio :label="1">命题赛场</el-radio>
            <el-radio :label="2">非命题赛场</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="card">
      <p class="title">选择赛题</p>
      <div class="card-con">
        <div class="radio-warp" v-if="ruleForm.themeType === 1">
          <el-radio-group v-model="ruleForm.themeId">
            <el-radio v-for="item in list.data.themeCompetition" :label="item.id">{{ item.name }}</el-radio>
          </el-radio-group>
        </div>
        <div class="radio-warp" v-if="ruleForm.themeType === 2">
          <el-radio-group v-model="ruleForm.otherId" style="margin-bottom:20px">
            <el-radio :label="item.id" v-for="item in list.data.otherThemeCompetition" :key="item.id">{{
              item.otherName }}</el-radio>
          </el-radio-group>
          <el-radio-group v-model="ruleForm.themeId">
            <el-radio v-for="item in otherCompetitionList" :key="item.id" :label="item.id">{{ item.name }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="card">
      <p class="title">上传作品</p>
      <div class="card-con" style="width:1200px">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
          <el-form-item label="作品名称" prop="worksName">
            <el-input v-model="ruleForm.worksName" placeholder="请输入作品名称" />
          </el-form-item>
          <el-form-item label="作者姓名" prop="author">
            <el-input v-model="ruleForm.author" placeholder="请输入作者姓名" />
          </el-form-item>
          <el-form-item label="指导老师">
            <el-input v-model="ruleForm.instructor" placeholder="请输入指导老师" />
          </el-form-item>
          <el-form-item label="设计理念" prop="idea">
            <el-input v-model="ruleForm.idea" placeholder="请输入设计理念" type="textarea" maxlength="1000" show-word-limit />
          </el-form-item>
          <el-form-item label="A3预览图(作品排版规范页面)" prop="mingImg">
            <el-upload class="avatar-uploader" v-model:file-list="fileListMingImg" :action="action.url"
              :headers="action.headers" accept="image/jpeg,image/png" list-type="picture-card" :limit="1"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :on-remove="hadleRemove">
              <el-icon class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
            <div class="tips">支持1张A3预览图，支持PNG、JPG格式，大小3MB以内</div>
          </el-form-item>
          <el-form-item label="上传作品附件" prop="worksFile1">
            <el-radio-group v-model="ruleForm.worksFileType" @change="uploadChange">
              <el-radio :label="0">图片作品</el-radio>
              <el-radio :label="1">视频作品</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <div v-if="ruleForm.worksFileType === 0">
              <el-upload class="avatar-uploader" v-model:file-list="fileListWorksFile1" :action="action.url"
                :headers="action.headers" accept="image/*" multiple list-type="picture-card" :limit="5"
                :on-success="handleAvatarSuccessWorksFile1More" :before-upload="beforeAvatarUpload"
                :on-remove="hadleRemoveWorksFile1More" :on-exceed="handleExceed">
                <el-icon class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
              <div class="tips2">选择图片作品，可上传不超过5张作品图，每张图片不超过3MB。</div>
            </div>
            <div v-if="ruleForm.worksFileType === 1">
              <el-upload class="avatar-uploader" :action="action.url" :headers="action.headers" accept="video/*"
                :limit="1" :on-success="handleAvatarSuccessWorksFile1" :before-upload="beforeAvatarUploadVideo"
                :on-remove="hadleRemoveWorksFile1" :on-exceed="handleExceed">
                <el-icon class="avatar-uploader-icon video-icon">
                  <Plus />
                </el-icon>
              </el-upload>
              <div class="tips2">选择视频作品，可上传1个视频作品，视频文件大小不超过500MB、时长不超过10分钟。</div>
            </div>
          </el-form-item>
          <el-form-item label="参赛报名表" prop="registration1">
            <div>
              <el-upload class="avatar-uploader" v-model:file-list="fileListRegistration" :action="action.url"
                :headers="action.headers" accept="image/*" multiple list-type="picture-card" :limit="5"
                :on-success="handleAvatarSuccessRegistration" :before-upload="beforeAvatarUpload"
                :on-remove="hadleRemoveRegistration" :on-exceed="handleExceed">
                <el-icon class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
              <div class="tips2">选择参赛报名表截图，可上传不超过5张参赛报名表截图，每张图片不超过3MB。</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="card">
      <p class="title">上传缴费凭证</p>
      <div class="card-con">
        <div class="info">
          <p class="name">{{ areaName.name }}收款方式：</p>
          <p>收款账号：{{ areaName.bankNo }}</p>
          <p>收款账号开户行：{{ areaName.bankName }}</p>
          <div class="code">
            <span>收款码：</span>
            <img v-if="areaName.payImg" :src="areaName.payImg" alt="" @click="handlePre(areaName.payImg)">
          </div>
          <p>请上传您的缴费凭证，支持JPG/PNG格式</p>
          <el-upload class="avatar-uploader" v-model:file-list="fileListPayImg" :action="action.url"
            :headers="action.headers" accept="image/jpeg,image/png" list-type="picture-card" :limit="1"
            :on-success="handleAvatarSuccessPayImg" :before-upload="beforeAvatarUpload" :on-remove="hadleRemovePayImg">
            <el-icon class="avatar-uploader-icon">
              <Plus />
            </el-icon>
          </el-upload>
        </div>
      </div>
    </div>
    <el-button type="primary" @click="submitForm(ruleFormRef)">
      提交审核
    </el-button>
    <el-dialog v-model="dialogVisible">
      <img style="width: 100%;" :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ArrowRightBold,
  Plus
} from '@element-plus/icons-vue'
import {
  ElMessage
} from 'element-plus'

import {
  ref,
  reactive,
  defineProps,
  watch,
  defineEmits,
  computed
} from 'vue'
import {
  getWorksBaseData,
  reSubmitWorks,
  saveWorksInfo
} from '@/api/competition'
import {
  getZqDictDetail
} from '@/api/publish'
import jsencrypt from '@/utils/jsencrypt'
import { Base64 } from 'js-base64';
import {
  useRouter
} from 'vue-router'
import { getToken } from '@/utils/auth'
const router = useRouter()
const emits = defineEmits(['signup'])
const props = defineProps({
  data: {
    type: Object,
    default () {
      return {}
    }
  },
  editData: {
    type: Object,
    default () {
      return {}
    }
  }
})

const action = reactive({
  url: process.env.VUE_APP_BASE_API + 'file/uploadFile',
  headers: {
    authorization: 'zq-website_' + getToken().token
  }
})

const fileListMingImg = ref([])
const fileListPayImg = ref([])
const fileListWorksFile1 = ref([])
const fileListRegistration = ref([])

function handleAvatarSuccess (response, uploadFile, uploadFiles) {
  if (response.data) {
    ruleForm.mingImg = response.data
  }
}

function handleAvatarSuccessPayImg (response) {
  if (response.data) {
    ruleForm.payImg = response.data
  }
}

function handleAvatarSuccessWorksFile1 (response, uploadFile, uploadFiles) {
  if (response.data) {
    ruleForm.worksFile1 = response.data
  }
}

function handleAvatarSuccessWorksFile1More (response, uploadFile, uploadFiles) {
  let key = ['worksFile1', 'worksFile2', 'worksFile3', 'worksFile4', 'worksFile5']
  key.forEach(v => {
    ruleForm[v] = ''
  })
  uploadFiles.forEach((v, i) => {
    if (v.response.data) {
      for (let value of key) {
        if (!ruleForm[value]) {
          ruleForm[value] = v.response.data
          break;
        }
      }
    }
  })
}
function handleAvatarSuccessRegistration (response, uploadFile, uploadFiles) {
  let key = ['registration1', 'registration2', 'registration3', 'registration4', 'registration5']
  key.forEach(v => {
    ruleForm[v] = ''
  })
  uploadFiles.forEach((v, i) => {
    if (v.response.data) {
      for (let value of key) {
        if (!ruleForm[value]) {
          ruleForm[value] = v.response.data
          break;
        }
      }
    }
  })
}

function beforeAvatarUpload (rawFile) {
  let fileType = ['image/jpeg', 'image/png']
  if (!fileType.includes(rawFile.type)) {
    ElMessage.error('图片格式只支持jpeg/png')
    return false
  } else if (rawFile.size / 1024 / 1024 > 3) {
    ElMessage.error('图片大小最大限制为3MB')
    return false
  }
}

function beforeAvatarUploadVideo (rawFile) {
  var url = URL.createObjectURL(rawFile);
  var audioElement = new Audio(url);
  var result;
  audioElement.addEventListener("loadedmetadata", function () {
    // 视频时长值的获取要等到这个匿名函数执行完毕才产生
    result = audioElement.duration; //得到时长为秒，小数，182.36
  });
  if (result > 600) {
    ElMessage.error('视频时长最大为10分钟')
    return false
  } else if (rawFile.size / 1024 / 1024 > 500) {
    ElMessage.error('视频最大大小为500MB')
    return false
  }
}

function hadleRemove (file, UploadFiles) {
  ruleForm.mingImg = ''
}
// 视频移除
function hadleRemoveWorksFile1 () {
  ruleForm.worksFile1 = ''
}
// 移除付款码
function hadleRemovePayImg () {
  ruleForm.payImg = ''
}

function hadleRemoveWorksFile1More (file, UploadFiles) {
  let key = ['worksFile1', 'worksFile2', 'worksFile3', 'worksFile4', 'worksFile5']
  let url = file.response.data
  for (let i = 0; i < key.length; i++) {
    if (ruleForm[key[i]] === url) {
      ruleForm[key[i]] = ''
      break;
    }
  }
}
function hadleRemoveRegistration (file, UploadFiles) {
  let key = ['registration1', 'registration2', 'registration3', 'registration4', 'registration5']
  let url = file.response.data
  for (let i = 0; i < key.length; i++) {
    if (ruleForm[key[i]] === url) {
      ruleForm[key[i]] = ''
      break;
    }
  }
}


function handleExceed (files, uploadFiles) {
  ElMessage.warning(
    `最大数量上次为5, 您选择了${files.length}个文件，您添加了 ${files.length + uploadFiles.length
    } 个文件`
  )
}

function uploadChange () {
  for (let i = 1; i < 6; i++) {
    ruleForm['worksFile' + i] = ''
  }

  fileListWorksFile1.value = []
}
let areaName = ref('')
const ruleFormRef = ref()
const ruleForm = reactive({
  areaId: '',
  themeId: '', // 命题id
  themeName: '', // 命题名字
  themeType: 1, // 命题类型id
  themeTypeName: '', // 命题类型名字

  worksName: '',
  instructor: '', // 指导老师
  author: '',
  idea: '',
  mingImg: '',
  worksFileType: 0, // 0 图片作品 1 视频作品
  worksFile1: '', // 作品附件
  worksFile2: '',
  worksFile3: '',
  worksFile4: '',
  worksFile5: '',

  registration1: '', // 参赛报名表截图
  registration2: '',
  registration3: '',
  registration4: '',
  registration5: '',

  payImg: '',

  otherId: 1, // 赛题分类，参数不参与
})


let list = reactive({
  data: []
})
let fullscreenLoading = ref(false)
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      if (check()) {
        fullscreenLoading = true
        if (props.editData) {
          let formData = JSON.parse(JSON.stringify(ruleForm))
          let paramStr = jsencrypt.encodeRSA(Base64.encode(JSON.stringify(formData)))
          reSubmitWorks({
            paramStr
          }).then(res => {
            fullscreenLoading = false
            ElMessage.success('重新提交成功')
            emits('signup')
          }).catch(e => {
            fullscreenLoading = false
          })
        } else {
          let formData = JSON.parse(JSON.stringify(ruleForm))
          formData.areaName = areaName.value.name
          formData.themeTypeName = formData.themeType === 1 ? '命题赛场' : '非命题赛场'
          formData.userId = props.data.id
          let item = concatList.list.find(v => v.id === formData.themeId)
          formData.themeName = item ? item.name : ''

          let paramStr = jsencrypt.encodeRSA(Base64.encode(JSON.stringify(formData)))
          saveWorksInfo({
            paramStr
          }).then(res => {
            fullscreenLoading = false
            ElMessage.success('提交成功')
            emits('signup')
          }).catch(e => {
            fullscreenLoading = false
          })
        }

      }

    } else { }
  })
}


function check () {
  if (!ruleForm.areaId) {
    ElMessage.warning('赛区不能为空')
    return false
  } else if (!ruleForm.themeId) {
    ElMessage.warning('请选择赛题')
    return false
  } else if (!ruleForm.payImg) {
    ElMessage.warning('请上传付款凭证')
  }
  return true
}
let dialogImageUrl = ref('')
let dialogVisible = ref(false)

function handlePre (path) {
  dialogImageUrl.value = path
  dialogVisible.value = true
}

function handleTypeChange () {
  ruleForm.themeId = ''
}

let concatList = reactive({
  list: []
})
let otherCompetitionList = computed(() => {
  if (list.data.otherThemeCompetition.length) {
    let clist = list.data.otherThemeCompetition.find(v => {
      return v.id === ruleForm.otherId
    })
    return clist.otherCompetitionList
  } else {
    return []
  }


})
getWorksBaseData().then(res => {
  list.data = res.data
  // concatList.list = [...res.data.themeCompetition, ...res.data.otherThemeCompetition]
  let concats = []
  concats.push(...res.data.themeCompetition)
  res.data.otherThemeCompetition.forEach(v => {
    concats.push(...v.otherCompetitionList)
  })
  concatList.list = concats
  ruleForm.areaId = res.data.areaList[0].id
  areaName.value = res.data.areaList[0]
  ruleForm.otherId = res.data.otherThemeCompetition[0].id
})
const validateWorksName = (rule, value, callback) => {
  let spaceReg = /\s/g
  if (value === '') {
    callback(new Error('请输入作品名称'))
  } else if (spaceReg.test(value)) {
    callback(new Error('请输入正确的作品名称'))
  } else {
    callback()
  }
}
const validateAuthor = (rule, value, callback) => {
  let spaceReg = /\s/g
  if (value === '') {
    callback(new Error('请输入作者姓名'))
  } else if (spaceReg.test(value)) {
    callback(new Error('请输入正确的作者姓名'))
  } else {
    callback()
  }
}
const validateIdea = (rule, value, callback) => {
  let spaceReg = /\s/g
  if (value === '') {
    callback(new Error('简要描述您的设计理念，支持1000字以内文本'))
  } else {
    callback()
  }
}
const validateWorksFile = (rule, value, callback) => {
  let list = ['worksFile1', 'worksFile2', 'worksFile3', 'worksFile4', 'worksFile5']
  let flag = list.find(v => {
    return ruleForm[v] != ''
  })
  flag ? callback() : callback(new Error('请上传作品附件'))
}
const validateRegistration = (rule, value, callback) => {
  let list = ['registration1', 'registration2', 'registration3', 'registration4', 'registration5']
  let flag = list.find(v => {
    return ruleForm[v] != ''
  })
  flag ? callback() : callback(new Error('请至少上传一张参赛报名表截图'))
}

const rules = reactive({
  worksName: [{
    required: true,
    validator: validateWorksName,
    trigger: 'blur'
  }],
  author: [{
    required: true,
    validator: validateAuthor,
    trigger: 'blur'
  }],
  idea: [{
    required: true,
    validator: validateIdea,
    trigger: 'blur'
  }],
  mingImg: {
    required: true,
    message: '请上传作品A3预览图'
  },
  worksFile1: {
    required: true,
    validator: validateWorksFile,
    trigger: ['blur']
  },
  registration1: {
    required: true,
    validator: validateRegistration,
    trigger: ['blur']
  }
})


function handleArea (v) {
  ruleForm.areaId = v.id
  areaName.value = v
}
watch(() => props.editData, (newVal) => {
  if (props.editData) {
    Object.assign(ruleForm, props.editData)
    if (props.editData.mingImg) {
      fileListMingImg.value[0] = {
        name: 'mingImg',
        url: props.editData.mingImg
      }
    }
    let worksFileList = []
    let registrationList = []
    let workKey = ['worksFile1', 'worksFile2', 'worksFile3', 'worksFile4', 'worksFile5']
    let registrationKey = ['registration1', 'registration2', 'registration3', 'registration4', 'registration5']
    workKey.forEach(k => {
      if (props.editData[k] != '') {
        worksFileList.push({
          name: k,
          response: {
            data: props.editData[k]
          },
          url: props.editData[k]
        })
      }
    })
    registrationKey.forEach(k => {
      if (props.editData[k] != '') {
        registrationList.push({
          name: k,
          response: {
            data: props.editData[k]
          },
          url: props.editData[k]
        })
      }
    })

    fileListWorksFile1.value = worksFileList
    fileListRegistration.value = registrationList

    if (props.editData.payImg) {
      fileListPayImg.value[0] = {
        name: 'payImg',
        url: props.editData.payImg
      }
    }
  }
}, {
  deep: true,
  immediate: true
})
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  font-size: 26px;
}

.content {
  padding-top: 50px;
  padding-bottom: 100px;

  .el-form {
    margin: 20px 0;
  }

  .card {
    ul {
      list-style: none;
    }

    .info {
      padding: 20px 0;

      .name {
        font-weight: 700;
        font-size: 17px;
      }

      p {
        margin-bottom: 10px;
      }

      .code {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        img {
          height: 100px;
        }
      }

    }

    .title {
      color: $themeColor;
      font-size: 18px;
      font-weight: 700;
      border-bottom: 1px solid #f1f1ff;
      padding-bottom: 20px;
    }

    .area-list {
      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;

        li {
          padding: 8px 16px;
          border-radius: 4px;
          background-color: #f2f2f2;
          margin-bottom: 20px;
          width: 83px;
          text-align: center;
          margin-right: 20px;
          cursor: pointer;
        }

        .on {
          background-color: $themeColor;
          color: #fff;
        }
      }
    }

    .radio-warp {
      margin: 20px 0;
    }

    ::v-deep .el-input__inner {
      height: 40px;
    }
  }
}

.tips {
  color: #f56c6c;
  margin-left: 20px;
}

.tips2 {
  color: #f56c6c;
}

::v-deep .el-form-item__content {
  align-items: flex-end;
}

.video-icon {
  width: 148px;
  height: 140px;
  border: 1px dashed #ccc;
}
</style>
